<!--<app-domain></app-domain>-->
<!--<hr>-->
<!--<app-max></app-max>-->
<!--<hr>-->
<!--<app-directives></app-directives>-->


<app-header></app-header>

<div class="container">
  <div class="row">
    <div class="col-md-12">
<!--      <app-recipes></app-recipes>-->
<!--      <app-shopping-list></app-shopping-list>-->
    </div>
  </div>




  <div class="row">
    <div class="col-xs-12">
      <app-game-control (intervalFired)="onIntervalFired($event)"></app-game-control>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <app-odd *ngFor="let oddNumber of oddNumbers" [number]="oddNumber" ></app-odd>
      <app-even *ngFor="let evenNumber of evenNumbers" [number]="evenNumber" ></app-even>
    </div>
  </div>

</div>

