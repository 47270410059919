import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-max',
  templateUrl: './max.component.html',
  styleUrls: ['./max.component.scss']
})
export class MaxComponent implements OnInit {
  username = '';

  constructor() { }

  ngOnInit(): void {
  }

}
