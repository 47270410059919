<div class="container">
  <div class="row">
    <div class="col-xs-12">


      <ol>
        <li>add a input field which updates a property ('username') via Two-way-Binding</li>
        <li>Output the username property via String Interpolation (in a paragraph below the input)</li>
        <li>Add a button which may only be clicked if the username is NOT an empty string</li>
        <li>Upon clicking the button, the username should be reset to an empty string</li>
      </ol>

      <hr>

      <label>Username</label>
      <input type="text" class="form-control" [(ngModel)]="username">
      <p>{{username}}</p>
      <button
        class="btn btn-primary"
        [disabled]="username === ''"
        (click)="username = ''">Reset User</button>
    </div>
  </div>
</div>
