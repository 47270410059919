<nav class="navbar navbar-default">
  <div class="container-fluid">
      <div class="navbar-header">
        <a href="#" class="navbar-brand">Recipe Book</a>
      </div>

    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li><a href="#">Recipe</a></li>
        <li><a href="#">Shopping List</a></li>

      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown">
          <a href="#" class="dropdown-toggle" role="button">Manage<span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li><a href="#">Save Data</a></li>
            <li><a href="#">Fetch Data</a></li>

          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!--<nav class="navbar navbar-expand-lg navbar-light bg-light">-->
<!--  <a class="navbar-brand" href="#">Recipe Book</a>-->
<!--  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">-->
<!--    <span class="navbar-toggler-icon"></span>-->
<!--  </button>-->
<!--  <div class="collapse navbar-collapse" id="navbarNavDropdown">-->
<!--    <ul class="navbar-nav">-->
<!--      <li class="nav-item active">-->
<!--        <a class="nav-link" href="#">Recipe<span class="sr-only">(current)</span></a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" href="#">Shopping List</a>-->
<!--      </li>-->

<!--      <li class="nav-item dropdown">-->
<!--        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--          Manage-->
<!--        </a>-->
<!--        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
<!--          <a class="dropdown-item" href="#">Save Data</a>-->
<!--          <a class="dropdown-item" href="#">Fetch Data</a>-->
<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</nav>-->
