<div class="row">
  <div class="col xs-10">
    <app-shopping-edit></app-shopping-edit>
    <hr>
        <ul class="list-group">
          <a
            class="list-group-item"
            style="cursor: pointer"
            *ngFor="let ingredient of ingredients"
          >
            {{ ingredient.name }} ({{ ingredient.amount }})
          </a>
        </ul>
  </div>
</div>
