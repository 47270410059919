<h1>Bind it!</h1>

<ol>
  <li> Create three new components: GameControl, Odd and Even</li>
  <li> the GameControl Component should have buttons to start and stop the game</li>
  <li> When starting the game, an event (holding a incrementing number) should get emitted each second (ref=setInterval())</li>
  <li> The event should be listenable from outside the component</li>
  <li> When stopping the game, no more events should get emitted (clearInterval(ref))</li>
  <li> A new odd component should get created for every  odd number emitted, the same should happen for the Even Component (on even numbers)</li>
  <li> Simply output Odd - NUMBER or Even - NUMBER in the two components</li>
  <li> Style the element (e.g. paragraph) holding your output text differently in both components</li>
</ol>




<button class="btn btn-success" (click)="onStartGame()">Start Game</button>
<button class="btn btn-danger" (click)="onPauseGame()">Pause Game</button>
